.background-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  min-width: 15rem;
  height: 30rem;
  overflow: hidden;
  background-size: 250% !important;
  transition: all ease-in-out 0.5s;
  @apply md:w-4/5 lg:w-[25rem] lg:h-[35rem] rounded-lg;
}

.background-card:hover {
  background-size: 100% !important;
  background-position: 0px 140% !important;
}

.technologie-card {
  background: rgb(16, 24, 40, 0.24);
}
.background-card:hover .card-background-item {
  rotate: 15deg;
  transition: all ease-in-out 0.5s;
}
.card-background-item {
  transition: all ease-in-out 0.5s;
  z-index: 0;
}

.card-image {
  transform: translate3d(0px, 0rem, 0px) scale3d(1.2, 1.2, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: all ease-in-out 0.5s;
}

.background-card:hover .card-image {
  transform: translate3d(0px, 2rem, 0px) scale3d(1.5, 1.5, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(-13deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: all ease-in-out 0.5s;
}

.background-card.active .card-image {
  transform: translate3d(0px, 2rem, 0px) scale3d(1.5, 1.5, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(-13deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: all ease-in-out 0.5s;
}
